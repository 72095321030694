.progress-bar {
  width: 0%;
  height: 100%;
  background-color: yellowgreen;
  animation: progress-down 5s linear;
}

@keyframes progress-down {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}